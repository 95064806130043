@import "bootstrap-customizations";
// Required
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";

// Optional
@import "~bootstrap/scss/_root";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_type";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_nav";
@import "~bootstrap/scss/_navbar";
@import "~bootstrap/scss/utilities/_flex";
@import "~bootstrap/scss/utilities/_spacing";
@import "~bootstrap/scss/utilities/_text";
@import "~bootstrap/scss/mixins/_grid-framework";

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: sans-serif !important;
}

.navbar-brand {
  img.logo {
    height: 70px;
  }
}

@media (max-width: 500px) {
  img.logo {
    height: 40px !important;
  }
}

.navbar-vida {
  padding: 15px 100px;
}

@media (max-width: 768px) {
  .navbar-vida {
    padding: 15px 20px !important;
  }
}

button:focus {
  outline: 0px;
}

.toggler-click {
  font-size: 2rem;
  font-weight: bold;
  background-color: #f5f7ff;
  text-align: center;
  .navbar-nav .nav-link {
    color: #144267 !important;
  }
  .navbar-nav .nav-link:hover {
    color: #4a8ebc !important;
  }
}

.close {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  content: " ";
  height: 1.5em;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.navbar-light .navbar-nav .nav-link {
  color: #144267;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0 20px;
  text-transform: uppercase;
  &:hover {
    color: #4a8ebc;
  }
}

.hero-section {
  margin-top: -130px;
  margin-bottom: 50px;
  padding-top: 180px;
  padding-bottom: 50px;
  background: url(../assets/img/header-background.png);
  background-position: top -80px right -120px;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;

  .reimagining-loyality {
    padding-left: 120px;
  }
  h1 {
    font-weight: normal;
    color: #144267;
    font-size: 2rem;
    line-height: 1.5;
  }
  .hero-section-image {
    img {
      width: 120%;
    }
  }
}

@media (max-width: 768px) {
  .hero-section {
    margin-top: -130px;
    padding-top: 150px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    background: url(../assets/img/header-background.png);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    .reimagining-loyality {
      padding-left: 30px;
      padding-right: 30px;
    }
    h1 {
      font-weight: normal;
      color: #144267;
      font-size: 1rem;
      line-height: 1.5;
    }
    .hero-section-image {
      img {
        width: 100%;
      }
      padding: 20px;
      margin-top: 40px;
    }
  }
}

.our-product {
  h1 {
    font-weight: 900;
    font-size: 4rem;
    color: #144267;
    line-height: 1.5;
    margin-bottom: -50px;
    @media (max-width: 576px) {
      font-size: 2rem;
      margin-bottom: 30px;
    }
  }
}

.learn-more {
  background-color: #48d8ef; /* Green */
  border: none;
  border-radius: 28px;
  padding: 8px 50px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: bold;
}

.product-details {
  font-size: 1.5rem;
  font-family: "Lato";
  line-height: 1.2;
  strong {
    font-weight: 900;
  }
  p, ul {
    margin-bottom: 35px;
  }
  ul {
    padding-inline-start: 22px;
  }
}

.unigigster-product {
  background: url(../assets/img/saveer-product-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .unigigster-logo {
    max-width: 260px;
    margin-bottom: 35px;
  }
  .product-details {
    margin-top: 30px;
    margin-bottom: 50px;
    @media (min-width: 577px) {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-left: 100px;
      padding-right: 100px;
    }
  }
}

.bm-product {
  margin-bottom: 100px;
  @media (max-width: 576px) {
    margin-bottom: 20px;
  }
  .bm-logo {
    max-width: 250px;
    margin-bottom: 10px;
  }
  .bm-image {
    img {
      margin-left: 15px;
    }
    @media (max-width: 576px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  .product-details {
    margin-top: 30px;
    @media (min-width: 577px) {
      padding-left: 100px;
      padding-right: 100px;
      margin-top: 100px;
    }
  }
}

.footprints-product {
  background: url(../assets/img/footprints-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
  @media (min-width: 577px) {
    margin-bottom: 80px;
    padding-bottom: 100px;
  }
  .footprints-logo {
    max-width: 260px;
    margin-bottom: 35px;
  }
  .product-details {
    margin-top: 30px;
    @media (min-width: 577px) {
      margin-top: 0px;
      padding-left: 100px;
      padding-right: 100px;
    }
  }
}


.talk-to-us {
  background: url(../assets/img/footer-background.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 180px 100px 40px 100px;
  margin-top: -100px;
  height: 1000px;
  img.img-contact {
    width: 250px;
    height: 250px;
  }
  h1 {
    font-weight: bold;
    font-size: 3.2rem;
    color: #144266;
    line-height: 1.5;
  }
  h2 {
    a {
      font-size: 2rem;
      color: #144266;
      line-height: 1.5;
    }
  }
  .talk-to-us-details {
    text-align: center;
  }
  .footer-menu {
    .nav-link {
      color: #144266;
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .social-icons {
    a.fa {
      float: right;
      padding: 10px;
      background: #4a8ebc;
      font-size: 1.5rem;
      color: white;
      width: 50px;
      border-radius: 50%;
      border: 2px solid #4a8ebc;
      text-align: center;
      text-decoration: none;
      margin: 5px 10px;
    }
    a.fa:hover {
      background: white;
      color: #4a8ebc;
    }
  }
}

.footer-section {
  .horizontal-row {
    border-top: 2px solid #e4e4e9;
  }
  .footer {
    padding-top: 30px;
    padding-bottom: 30px;
    vertical-align: middle;
    .footer-text {
      border-left: 2px solid #e4e4e9;
      padding: 0px 20px;
      p {
        font-size: 14px;
        color: #4a8ebc;
      }
    }
  }
}

@media (max-width: 768px) {
  .talk-to-us {
    background: url(../assets/img/footer-background.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -160px;
    padding: 230px 0px 40px 0px;
    img.img-contact {
      width: 150px;
      height: 150px;
    }
    h1 {
      font-weight: bold;
      font-size: 2rem;
      color: #144266;
      line-height: 1.5;
    }
    h2 {
      a {
        font-size: 1.2rem;
        color: #144266;
        line-height: 1.5;
      }
    }
    .talk-to-us-details {
      text-align: center;
    }
  }
  .footer-section {
    .horizontal-row {
      border-top: 2px solid #e4e4e9;
    }
    .footer {
      img.img-footer {
        width: 90%;
      }
      padding-top: 10px;
      padding-bottom: 10px;
      .footer-text {
        border-left: 2px solid #e4e4e9;
        padding: 5px 10px !important;
        p {
          font-size: 14px;
          color: #4a8ebc;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .talk-to-us {
    margin-top: -160px;
    padding: 230px 0px 40px 0px;
    height: 1100px;
    h1 {
      font-weight: bold;
      font-size: 2rem;
      color: #144266;
      line-height: 1.5;
    }
    h2 {
      a {
        font-size: 1.2rem;
        color: #144266;
        line-height: 1.5;
      }
    }
    .talk-to-us-details {
      text-align: center;
    }
    .footer-menu-items {
      display: inline-block;

      .footer-menu {
        .nav-link {
          color: #144266;
          font-size: 0.9rem;
          font-weight: 500;
          text-transform: uppercase;
        }
      }
      .social-icons {
        a.fa {
          float: left;
          padding: 10px;
          background: #4a8ebc;
          font-size: 1.5rem;
          color: white;
          width: 50px;
          border-radius: 50%;
          border: 2px solid #4a8ebc;
          text-align: center;
          text-decoration: none;
          margin: 5px 10px;
        }
        a.fa:hover {
          background: white;
          color: #4a8ebc;
        }
      }
    }
  }

  .footer-section {
    .horizontal-row {
      border-top: 2px solid #e4e4e9;
    }
    .footer {
      img.img-footer {
        width: 40%;
      }
      padding-top: 10px;
      padding-bottom: 10px;
      display: inline-block;
      vertical-align: middle;
      .footer-text {
        padding: 10px 20px !important;
        border: none !important;
        p {
          font-size: 14px;
          color: #4a8ebc;
        }
      }
    }
  }
}
